import React, {useCallback, useEffect, useState} from 'react'
import {useDirectus} from '../../directus/DirectusContext'
import Attendance from '../../directus/types/schema/Attendance'
import Question from '../../directus/types/schema/Question'
import AttendanceScreen from '../../screens/AttendanceScreen'
import HomeScreen from '../../screens/HomeScreen'
import LoginScreen from '../../screens/LoginScreen'
import MyAnsweredQuestionsScreen from '../../screens/MyAnsweredQuestionsScreen'
import QuestionsScreen from '../../screens/QuestionsScreen'
import AsyncQueue from '../../utils/AsyncQueue'
import Sidebar from '../sidebar/Sidebar'

const queue = new AsyncQueue()

function App() {
	const {client, user} = useDirectus()

	const [screen, setScreen] = useState<string | null>(null)

	const [questions, setQuestions] = useState<Question[]>([])
	const [myAnsweredQuestions, setMyAnsweredQuestions] = useState<Question[]>([])
	const [attendances, setAttendances] = useState<Attendance[]>([])

	const subscribeQuestions = useCallback(async () => {
		const fields = [
			'id',
			'aid',
			'status',
			'created_date',
			'updated_date',
			'veterinarian_accepted_date',
			'veterinarian_answered_date',
			'name',
			'created_user.id',
			'created_user.aid',
			'created_user.email',
			'created_user.first_name',
			'created_user.last_name',
			'created_user.avatar.id',
			'animal.id',
			'animal.name',
			'animal.avatar.id',
			'veterinarian_user.id',
			'veterinarian_user.email',
			'veterinarian_user.first_name',
			'veterinarian_user.last_name',
			'veterinarian_user.avatar.id',
		]

		client.sendMessage({
			uid: `questions`,
			type: 'items',
			collection: 'questions',
			action: 'read',
			query: {
				limit: -1,
				sort: 'aid',
				fields,
				filter: {
					status: {
						_in: ['pending', 'accepted'],
					},
				},
			},
		})

		client.sendMessage({
			uid: `questions`,
			type: 'subscribe',
			collection: 'questions',
			query: {
				fields,
				filter: {
					status: {
						_nin: ['new'],
					},
				},
			},
		})
	}, [client])

	const subscribeMyAnsweredQuestions = useCallback(async () => {
		const fields = [
			'id',
			'aid',
			'status',
			'created_date',
			'updated_date',
			'veterinarian_accepted_date',
			'veterinarian_answered_date',
			'name',
			'created_user.id',
			'created_user.aid',
			'created_user.email',
			'created_user.first_name',
			'created_user.last_name',
			'created_user.avatar.id',
			'animal.id',
			'animal.name',
			'animal.avatar.id',
			'veterinarian_user.id',
			'veterinarian_user.email',
			'veterinarian_user.first_name',
			'veterinarian_user.last_name',
			'veterinarian_user.avatar.id',
		]

		client.sendMessage({
			uid: `myAnsweredQuestions`,
			type: 'items',
			collection: 'questions',
			action: 'read',
			query: {
				limit: -1,
				sort: '-aid',
				fields,
				filter: {
					status: {
						_in: ['answered'],
					},
					veterinarian_user: {
						id: {
							_eq: user?.id ?? '-1',
						},
					},
				},
			},
		})

		client.sendMessage({
			uid: `myAnsweredQuestions`,
			type: 'subscribe',
			collection: 'questions',
			query: {
				fields,
				filter: {
					status: {
						_in: ['answered'],
					},
					veterinarian_user: {
						id: {
							_eq: user?.id ?? '-1',
						},
					},
				},
			},
		})
	}, [client, user])

	const subscribeAttendances = useCallback(async () => {
		const fields = [
			'id',
			'date_from',
			'date_till',
			'status',
			'type',
			'user.id',
			'user.first_name',
			'user.last_name',
			'user.email',
			'user.avatar.id',
			'user.color',
		]

		const filter = {
			date_till: {
				_gte: '$NOW',
			},
		}

		client.sendMessage({
			uid: `attendances`,
			type: 'items',
			collection: 'attendances',
			action: 'read',
			query: {
				limit: -1,
				sort: 'date_from',
				fields,
				filter,
			},
		})

		client.sendMessage({
			uid: `attendances`,
			type: 'subscribe',
			collection: 'attendances',
			query: {
				fields,
				filter,
			},
		})
	}, [client])

	useEffect(() => {
		if (user === null) {
			return
		}

		const removeEventHandler = client.onWebSocket('message', function (message) {
			console.log(message)

			if (message.type === 'ping') {
				client.sendMessage({type: 'pong'})
			}

			if (message.type === 'auth' && message.status === 'ok') {
				subscribeQuestions().then().catch()
				subscribeMyAnsweredQuestions().then().catch()
				subscribeAttendances().then().catch()
			}

			if (
				message.uid === 'questions' &&
				(
					message.type === 'items' ||
					(message.type === 'subscription' && ['create', 'update'].includes(message.event))
				)
			) {
				for (const item of message.data) {
					setQuestions((prevState) => {
						const index = prevState.findIndex(i => i.id === item.id)

						if (index !== -1) {
							const updatedItems = [...prevState]

							updatedItems[index] = {...item}

							return updatedItems
						}

						return [...prevState, item]
					})
				}
			}

			if (
				message.uid === 'myAnsweredQuestions' &&
				(
					message.type === 'items' ||
					(message.type === 'subscription' && ['create', 'update'].includes(message.event))
				)
			) {
				for (const item of message.data) {
					setMyAnsweredQuestions((prevState) => {
						const index = prevState.findIndex(i => i.id === item.id)

						if (index !== -1) {
							const updatedItems = [...prevState]

							updatedItems[index] = {...item}

							return updatedItems
						}

						return [item, ...prevState]
					})
				}
			}

			if (
				message.uid === 'attendances' &&
				(
					message.type === 'items' ||
					(message.type === 'subscription' && ['create', 'update'].includes(message.event))
				)
			) {
				for (const item of message.data) {
					setAttendances(prevState => {
						const index = prevState.findIndex(i => i.id === item.id)

						if (index !== -1) {
							const updatedItems = [...prevState]

							updatedItems[index] = {...item}

							return updatedItems
						}

						return [...prevState, item]
					})
				}
			}
		})

		queue.enqueue(async () => {
			try {
				await client.connect()
			} catch (error) {
			}
		})

		return () => {
			queue.enqueue(async () => {
				removeEventHandler()

				client.disconnect()

				await new Promise((resolve) => setTimeout(resolve, 1000))
			})
		}
	}, [client, user, subscribeQuestions, subscribeMyAnsweredQuestions, subscribeAttendances])

	if (!user) {
		return <LoginScreen/>
	}

	return (
		<div className={`flex flex-col md:flex-row w-screen min-h-screen`}>
			<Sidebar screen={screen} setScreen={setScreen}/>
			<div
				className="p-4 flex-grow"
			>
				{screen === null && <HomeScreen setScreen={setScreen} questions={questions} myAnsweredQuestions={myAnsweredQuestions} attendances={attendances}/>}
				{screen === 'questions' && <QuestionsScreen questions={questions}/>}
				{screen === 'myAnsweredQuestions' && <MyAnsweredQuestionsScreen questions={myAnsweredQuestions}/>}
				{screen === 'attendance' && <AttendanceScreen attendances={attendances}/>}
			</div>
		</div>
	)
}

export default App
