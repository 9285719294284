import {useMemo} from 'react'
import {useDirectus} from '../directus/DirectusContext'
import Attendance from '../directus/types/schema/Attendance'
import Question from '../directus/types/schema/Question'

type HomeScreenPropsType = {
	setScreen: (screen: null | string) => void,
	questions: Question[]
	myAnsweredQuestions: Question[]
	attendances: Attendance[]
}

const HomeScreen = (
	{
		setScreen,
		questions,
		myAnsweredQuestions,
		attendances,
	}: HomeScreenPropsType
) => {
	const {user} = useDirectus()

	const myAcceptedQuestionsCount = useMemo(() => {
		return questions.filter(q => q.status === 'accepted' && user && user.id === q.veterinarian_user?.id).length
	}, [questions])

	const pendingQuestionsCount = useMemo(() => {
		return questions.filter(q => q.status === 'pending').length
	}, [questions])

	const myAnsweredQuestionsCount = useMemo(() => {
		return myAnsweredQuestions.length
	}, [myAnsweredQuestions])

	const pointsOfInterest = [
		{
			title: 'Mnou přijaté dotazy',
			value: myAcceptedQuestionsCount,
			backgroundColor: 'bg-red-100',
			foregroundColor: 'text-red-500',
			onClick: () => setScreen('questions'),
		},
		{
			title: 'Dotazy čekající na zodpovězení',
			value: pendingQuestionsCount,
			backgroundColor: 'bg-orange-100',
			foregroundColor: 'text-orange-500',
			onClick: () => setScreen('questions'),
		},
		{
			title: 'Mnou zodpovězené dotazy',
			value: myAnsweredQuestionsCount,
			backgroundColor: 'bg-blue-100',
			foregroundColor: 'text-blue-500',
			onClick: () => setScreen('myAnsweredQuestions'),
		},
	]

	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
			{pointsOfInterest.map((poi, index) => (
				<div
					key={index}
					className={`flex flex-col items-center justify-center p-6 gap-4 rounded-xl shadow-md cursor-pointer ${poi.backgroundColor}`}
					onClick={poi.onClick}
				>
					<span className="text-sm font-semibold text-gray-500">
						{poi.title}
					</span>
					<span className={`text-5xl font-bold ${poi.foregroundColor}`}>
						{poi.value}
					</span>
				</div>
			))}
		</div>
	)
}

export default HomeScreen
