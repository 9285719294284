import {useMemo, useState} from 'react'
import QuestionDetail from '../components/questions/QuestionDetail'
import QuestionsList from '../components/questions/QuestionsList'
import Question from '../directus/types/schema/Question'

type MyQuestionsScreenPropsType = {
	questions: Question[]
}

const MyAnsweredQuestionsScreen = (
	{
		questions
	}: MyQuestionsScreenPropsType,
) => {
	const [questionId, setQuestionId] = useState<null | string>(null)

	return (
		<>
			{questionId === null && (
				<QuestionsList
					title="Mnou zodpovězené dotazy"
					questions={questions}
					setQuestionId={setQuestionId}
				/>
			)}
			{questionId !== null && (
				<QuestionDetail
					key={questionId}
					close={() => setQuestionId(null)}
					questions={questions}
					questionId={questionId}
				/>
			)}
		</>
	)
}

export default MyAnsweredQuestionsScreen
